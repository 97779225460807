import { mapGetters } from 'vuex'

export default {
  props: {
    campaign: { required: false }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest',
      'sqlDateEnd',
      'sqlDateBegin',
      'placeIds',
      'dashboardFilterReady',
    ]),
    indicatorProcessingTimeAlias() {
      return 'insatisfactionsAvgProcessingTime'
    },
    indicatorToTreatTimeAlias() {
      return 'insatisfactionsAvgToTreatTime'
    }
  },
  methods: {
    rankFor(requestDatas, aliasName) {
      if (requestDatas && this.isMonoPlace) {
        const rank = requestDatas
          .filter(place => place[aliasName] != null)
          .findIndex((place) => {
            return place.placeCampaignsPlaceId == this.placeIds[0]
          })
        // findIndex return -1 when not match found
        return rank != -1 ? rank + 1 : null
      } else {
        return null
      }
    },
    nbInsatisfactionsRequest() {
      if (!this.dashboardFilterReady) {
        return
      }

      this.loading = true

      let request = this.dashboardFilterRequest.select({
        insatisfactions: ['nb_insatisfactions']
      }).where({
        "campaign_id": this.campaign.id,
        "created_at": { "<=>": { min: this.sqlDateBegin, max: this.sqlDateEnd } }
      })

      this.loading = false
      return request
    },
    avgProcessingTimeRequest({ dateBegin, dateEnd, aliasName } = {}) {
      if (!this.dashboardFilterReady) {
        return
      }
      dateBegin = dateBegin || this.sqlDateBegin
      dateEnd = dateEnd || this.sqlDateEnd
      aliasName = aliasName || this.indicatorProcessingTimeAlias
      this.loading = true

      let request = this.dashboardFilterRequest.select({
        insatisfaction_processing_times: [
          this.avgProcessingTimeColumns({ dateEnd, aliasName })
        ]
      }).where({
        "place_campaigns_campaign_id": this.campaign.id,
        "status": { 'not_in': ['archived', 'not_processed'] },
        "created_at": { "<=>": { min: dateBegin, max: dateEnd } }
      })

      this.loading = false
      return request
    },
    nbInsatisfactionsProcessedRequest() {
      if (!this.dashboardFilterReady) {
        return
      }

      this.loading = true

      let request = this.dashboardFilterRequest.select({
        insatisfaction_processing_times: [{
          nb_processed_insatisfactions: {
            as: 'nb_processed_insatisfactions',
            max_processed_date: this.sqlDateEnd
          }
        }]
      }).where({
        "place_campaigns_campaign_id": this.campaign.id,
        "status": { 'not_in': ['archived', 'not_processed'] },
        "created_at": { "<=>": { min: this.sqlDateBegin, max: this.sqlDateEnd } }
      })
      return request
    },
    avgToTreatTimeRequest({ dateEnd, aliasName } = { }) {
      if (!this.dashboardFilterReady) {
        return
      }

      aliasName = aliasName || this.indicatorToTreatTimeAlias
      dateEnd = dateEnd || this.sqlDateEnd

      this.loading = true

      let request = this.dashboardFilterRequest.select({
        insatisfaction_to_treat_times: [
          this.avgToTreatTimeColumns({ dateEnd, aliasName })
      ]}).where({
        "place_campaigns_campaign_id": this.campaign.id
      })

      this.loading = false
      return request
    },
    nbInsatisfactionToTreatRequest({ dateEnd, aliasName } = { }) {
      if (!this.dashboardFilterReady) {
        return
      }

      dateEnd = dateEnd || this.sqlDateEnd
      this.loading = true

      let request = this.dashboardFilterRequest.select({
        insatisfaction_to_treat_times: [{
          nb_insatisfaction_to_treat: {
            as: 'nb_insatisfaction_to_treat',
            date_end: dateEnd
          }
        }]
      }).where({
        "place_campaigns_campaign_id": this.campaign.id
      })
      return request
    },
    avgToTreatTimeColumns({ dateEnd, aliasName } = { }) {
      dateEnd = dateEnd || this.sqlDateEnd
      aliasName = aliasName || this.indicatorToTreatTimeAlias

      return {
        avg_to_treat_time: {
          date_end: dateEnd,
          as: aliasName
        }
      }
    },
    avgProcessingTimeColumns({ dateEnd, aliasName }) {
      dateEnd = dateEnd || this.sqlDateEnd
      aliasName = aliasName || this.indicatorToTreatTimeAlias

      return {
        avg_processing_time: {
          max_processed_date: dateEnd,
          as: aliasName
        }
      }
    }
  },
}
