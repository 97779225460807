<template>
  <WStatsWidget
    v-if="campaign === undefined || campaignIndicator === undefined"
    :title="$t('widget_evolution_title')"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <EvolutionWidget
    v-else
    :title="title"
    :basedRequest="basedRequest"
    :campaign="campaign"
    :indicatorAlias="indicatorAlias"
    :avgScoreScale="campaign.avgScoreScale"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import EvolutionWidget from '@statistics/shared/widgets/EvolutionWidget'

  export default {
    name: "CampaignIndicatorEvolutionWidget",
    props: {
      campaign: { type: Object },
      campaignIndicator: { type: Object },
    },
    components: {
      EvolutionWidget,
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
      ]),
      basedRequest() {
        let request =  this.dashboardFilterRequest

        if (this.campaign) {
          request = request.where({ campaign_id: this.campaign.id })
        }

        if (this.campaignIndicator.indicatorType == 'campaignScoreIndicator') {
          request = request.select({
            voters: [
              this.indicatorColumn,
              { nb_review: { as: 'nbReview' } }
            ]
          })

          if (this.campaignIndicator.formulaToIdKey) {
            request = request.where({
              [this.campaignIndicator.formulaToIdKey]: this.campaignIndicator.formulaToId
            })
          }
        } else {
          request = request.select({
            voters: [
              this.indicatorColumn,
              { nb_review: { as: 'nbReview' } }
            ]
          })
        }

        return request
      },
      indicatorColumn() {
        if (this.campaignIndicator.indicatorType == 'campaignScoreIndicator') {
          return { [this.campaignIndicator.formulaToColumn]: { as: this.indicatorAlias } }
        } else {
          return { [`avg_score_campaign_indicator_`]: { campaign_indicator_id: this.campaignIndicator.id, as: this.indicatorAlias } }
        }
      },
      indicatorAlias() {
        return 'score'
      },
      title() {
        const title = this.campaignIndicator.indicatorType == 'campaignScoreIndicator' ?
                      this.campaignIndicator.formulaToName :
                      this.campaignIndicator.name
        return `${this.$t('widget_evolution_title')} - ${title}`
      }
    }
  }
</script>
