<template>
  <div class="chart-time-navigation">
    <button
      data-html2canvas-ignore
      v-if="options.from > options.minDate"
      class="previous"
      v-on:click="goPrevious"
    >
      <v-icon left>mdi-arrow-left</v-icon>
    </button>
    <button
      data-html2canvas-ignore
      v-if="options.to < options.maxDate"
      class="next"
      v-on:click="goNext"
    >
      <v-icon right>mdi-arrow-right</v-icon>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      options: { required: true, type: Object },
      refreshData: { required: true, type: Function }
    },
    name: "WTimeNavigation",
    computed: {

    },
    methods: {
      goNext() {
        if(this.options.to < this.options.maxDate) {
          this.options.from = this.options.from.add(
            this.options.periodNumber,
            this.options.unit
          )
          this.options.to = this.options.to.add(
            this.options.periodNumber,
            this.options.unit
          ).endOf(this.options.unit)
          this.refreshData()
        }
      },
      goPrevious() {
        if(this.options.from > this.options.minDate) {
          this.options.from = this.options.from.subtract(
            this.options.periodNumber,
            this.options.unit
          )
          this.options.to = this.options.to.subtract(
            this.options.periodNumber,
            this.options.unit
          ).endOf(this.options.unit)
          this.refreshData()
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .chart-time-navigation
    margin-left: 18px
    position: relative
    padding: 8px

  .chart-time-navigation button.previous
    float: left

  .chart-time-navigation button.next
    float: right
</style>
