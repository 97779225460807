import { mapGetters } from 'vuex'

export default {
  props: {
    voter: { required: true }
  },
  data: () => ({
    reload: 0,
    forceUpdate: false
  }),
  computed: {
    ...mapGetters([
      'currentDashboardCampaignWithId',
      'currentUser',
      'currentUserNeedValidationForCampaign',
      'voterUpdateEvent',
      'currentDashboardRankingRelatedIndicator',
      'mainCampaign',
      'isDashboardMulti',
      'rightAnswerCustomersChannels',
      'currentDashboardInsatisfactionPreferenceReferralByCampaignId',
    ]),
    trustvillePublicLabel() {
      return this.getVoter?.fullTrustvilleStates?.subStates[0]?.label
    },
    trustvillePublicState() {
      return this.getVoter?.fullTrustvilleStates?.publicState?.name
    },
    voterIsTrustvilleReportable() {
      return ['soon_online', 'online'].includes(this.trustvillePublicState)
    },
    icon() {
      if (this.mainCampaign.trustvilleProductPreferenceEnabled) {
        if (this.voter.precalculatedProductStatus) {
          return { icon: 'trustville', text: 'published', color: '#65c095' }
        } else {
          return { icon: 'trustville', text: 'unpublished', color: '#7a7a7a' }
        }
      } else if (this.mainCampaign.trustvillePreferenceEnabled && this.voter.trustvillePageOrWidgetEnabled) {
        if (this.voter.diffusedOnTrustville) {
          return { icon: 'trustville', text: 'published', color: '#65c095' }
        } else {
          return { icon: 'trustville', text: 'unpublished', color: '#7a7a7a' }

        }
      } else {
        return { icon: 'wizville', text: '', color: 'lightgrey' }
      }
    },
    voterIsPublicPlatform() {
      return [
        'google',
        'facebook',
        'pages_jaunes',
        'trip_advisor'
      ].includes(this.voter.source)
    },
    voterScale() {
      const campaign = this.currentDashboardCampaignWithId(this.voter.campaignId)

      if (this.voterIsPublicPlatform) {
        return 5
      } else {
        return this.voterScoreType === 'nps' ? 10 : campaign?.avgScoreScale?.max
      }
    },
    voterCreatedOn() {
      return this.$date(this.voter.createdAt)
        .locale(this.$i18n.locale)
        .format('DD/MM/YY')
    },
    voterCreatedAt() {
      return this.$date(this.voter.createdAt)
        .locale(this.$i18n.locale)
        .format('DD/MM/YY - H[h]mm')
    },
    voterName() {
      const firstName = this.voter.firstName
      const lastName = this.voter.lastName
      const author = this.voter.author

      if (firstName == 'xxxxx' && lastName == 'xxxxx') return 'anonymized'

      if (firstName && firstName !=='anonymized' && lastName && lastName !=='anonymized') {
        return `${firstName} ${lastName}`
      }
      return author !== 'anonymized' ? author : 'anonymized'
    },
    voterInsatisfactionForCurrentGroup() {
      return !!(
        !this.getVoter?.isInsatisfactionTransfered ||
        this.getVoter?.insatisfactionUserGroupId === this.currentUser?.userGroupId
      )
    },
    getVoter() {
      if (this.forceUpdate) {
        return this.fetchVoter
      } else {
        return this.voter
      }
    },
    isConversationManagedByCurrentUser() {
      return (this.getVoter?.conversationManagerId === this.currentUser.id)
    },
    voterProcessingStatus() {
      return this.getVoter?.processingStatus
    },
    voterFirstTreatmentAction() {
      return this.getVoter?.firstTreatmentAction
    },
    voterNeedsMessageReview() {
      return [
        'pending_validation',
        'conversation_need_answer'
      ].includes(this.voterProcessingStatus)
    },
    voterNeedsProcessing() {
      return [
        'insatisfaction_not_processed',
        'pending_validation',
        'conversation_need_answer'
      ].includes(this.voterProcessingStatus)
    },
    voterScoreExist() {
      return typeof this.voterScore == 'number'
    },
    voterScore() {
      return this.voterScoreType === 'avg_score' ? this.voter.avgScore : this.voter.nps
    },
    voterScoreType() {
      const voterCampaignId = this.voter.campaignId
      const campaignReferal = this.currentDashboardInsatisfactionPreferenceReferralByCampaignId.find((referral) => referral.campaign_id === voterCampaignId);

      if (campaignReferal !== undefined || campaignReferal !== null) {
        return campaignReferal.referral === 'nps' ? 'nps' : 'avg_score';
      }
      // Fallback solution!
      const rankingRelatedIndicator = this.isDashboardMulti
        ? this.currentDashboardCampaignWithId(this.voter.campaignId)?.dashboardRankingRelatedIndicator
        : this.currentDashboardRankingRelatedIndicator;
      return rankingRelatedIndicator === 'nps_score' ? 'nps' : 'avg_score';
    },
    voterMixin_customerAvailableChannels() {
      const channels = []
      const emailChannelRight = this.currentUser.isAdmin ||
                                this.rightAnswerCustomersChannels.includes('email')
      const smsChannelRight = this.currentUser.isAdmin ||
                              this.rightAnswerCustomersChannels.includes('sms')
      const publicChannelRight = this.currentUser.isAdmin ||
                                 this.rightAnswerCustomersChannels.includes(this.voter.source)

      if (this.voter.email && emailChannelRight) {
        channels.push('email')
      }
      if (this.voter.phoneNumber && smsChannelRight) {
        channels.push('sms')
      }
      if (this.voterIsPublicPlatform && publicChannelRight) {
        channels.push(this.voter.source)
      }

      return channels
    },
    voterMixin_voterCanBeCalled() {
      return !!this.voter.phoneNumber
    },
    voterMixin_customerPriorityChannel() {
      return this.voterMixin_customerAvailableChannels[0]
    },
    voterMixin_hasVoterAutomatedMessageTreatmentScheduled() {
      return this.voter.hasVoterAutomatedMessageTreatmentScheduled
    }
  },
  asyncComputed: {
    fetchVoter: {
      lazy: true,
      watch: ['reload'],
      async get() {
        const request = this.$basedRequest().select({
          voters: [
            'has_voter_automated_message_treatment_scheduled',
            'processing_status',
            'first_treatment_action',
            'insatisfaction_user_group_id',
            'is_insatisfaction_transfered',
            'conversation_manager_id',
            'full_trustville_states',
            'source'
          ]
        }).where({ id: this.voter.id })

        const result = (await this.$resolve(request, 'fetchVoter')).first()

        return result
      }
    },
    voterMixin_currentVoterOtherVotersUniqueIds: {
      lazy: true,
      default: [],
      async get() {
        const req = this.$basedRequest().select({
          voters: [
            { 'unique_id': { as: 'uniqueId' } },
          ],
        }).where({
          customer_id: this.voter.customerId,
          place_campaign_id: this.voter.placeCampaignId
        }).order(
          ['created_at', 'desc']
        )

        return (await this.$resolve(req)).data.voters.map(voter => voter.uniqueId)
      }
    }
  },
  methods: {
    async voterMixin_currentVoterPaginatedOtherVoters(limit, offset) {
      const req = this.$basedRequest().select({
        voters: [
          'id',
          'customer_id',
          'author',
          'first_name',
          'last_name',
          'unique_id',
          'created_at',
          'source',
          'nps',
          'avg_score',
          'insatisfaction_user_group_id',
          'conversation_manager_id',
          'is_insatisfaction_transfered',
          'processing_status',
          'has_voter_automated_message_treatment_scheduled',
          'places_name',
          'is_renotation',
          { campaigns_id: { as: 'campaign_id'} },
          'insatisfaction_status',
          'main_verbatim_content',
          'full_trustville_states'
        ],
      }).where({
        customer_id: this.voter.customerId,
        place_campaign_id: this.voter.placeCampaignId
      }).order(
        ['created_at', 'desc']
      ).limit(
        limit
      ).offset(
        offset
      )

      const response = await this.$resolve(req)

      return response.data.voters
    },
    voterMixin_goToVoter(uniqueId, pushState = true) {
      if (uniqueId !== this.voter?.uniqueId) {
        if (this.$route.params?.voterUniqueId !== uniqueId) {
          const target = { params: {
              voterUniqueId: uniqueId,
              section: 'review'
            }
          }

          this.$store.dispatch('collapseVoter')
          if (pushState) {
            this.$router.push(target)
          } else {
            this.$router.replace(target)
          }
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }
    }
  },
  watch: {
    voterUpdateEvent: function(voterUpdateEvent) {
      if (voterUpdateEvent.voter.uniqueId === this.voter?.uniqueId) {
        this.forceUpdate = true
        this.reload = this.reload + 1
      }
    }
  }
}
