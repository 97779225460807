import { mapGetters } from 'vuex'
import { sqlDate } from '@vue/plugins/helpers/dates'

export default {
  computed: {
    ...mapGetters([
      'currentUser',
      'currentDashboardTreatVotersPeriod',
      'currentDashboardInsatisfactionNotificationEnabled',
      'currentDashboardScoreScale'
    ]),
    periodSelectorEnabled() {
      return false
    },
    filteredVoterRequestBase() {     
      let filteredVoterRequestBase = this.votersRequestFilter(this.status).order(
        [this.localFilters.sortFilters.sortValue, this.localFilters.sortFilters.order, "nulls last"]
      )

      filteredVoterRequestBase = this.applyFilters(
        filteredVoterRequestBase,
        this.status,
        this.subStatus,
        this.localFilters.treatFilters
      )

      filteredVoterRequestBase = this.applySearchFilter(filteredVoterRequestBase, this.localFilters?.searchFilter)

      if (this.status === 'all') {
        filteredVoterRequestBase = this.applyScoreFilters(filteredVoterRequestBase, this.localFilters?.scoreFilters)
      }

      return filteredVoterRequestBase
    }
  },
  methods: {
    groupBy(subStatus) {
      return {
        new_feedbacks: 'insatisfaction_user_group_id',
        new_messages: 'conversation_manager_id'
      }[subStatus]
    },
    select(subStatus) {
      return {
        new_feedbacks: {
          user_groups: ["name", "id"],
        },
        new_messages: {
          users: ["mail", "id"],
        }
      }[subStatus]
    },
    async getCount(status, subStatus, treatFilters, scoreFilters) {
      let totalVoterRequest = this.votersRequestFilter(status).select(
        { voters: [ "COUNT_DISTINCT_id" ] }
      )

      totalVoterRequest = this.applyFilters(
        totalVoterRequest,
        status,
        subStatus,
        treatFilters
      )

      if (scoreFilters) {
        totalVoterRequest = this.applyScoreFilters(totalVoterRequest, scoreFilters)
      }
      const total = (await this.$resolve(totalVoterRequest, 'getCount')).first()?.countDistinctId || 0

      return total
    },
    async getCountGroupedBy(status, subStatus) {
      let totalVoterRequest = this.votersRequestFilter(status).select(
        { voters: [ "COUNT_DISTINCT_id" ] }
      ).group([this.groupBy(subStatus)])

      totalVoterRequest = this.applyFilters(totalVoterRequest, status, subStatus)

      const data = (await this.$resolve(totalVoterRequest, 'getCountGroupedBy'))?.data || {}

      return data
    },
    async getGroupedByData(ids, subStatus) {
      const request = this.$basedRequest()
        .select(this.select(subStatus))
        .where({
          id: ids,
        })
        
      const itemsData = await this.$resolve(request, 'getGroupedByData')

      return itemsData.data.userGroups || itemsData.data.users
    },
    async getVotersFromApi() {
      const voterUniqueIds = await this.getVoterUniqueIds()
      const voterData = await this.getVoterData(voterUniqueIds, this.localFilters)

      return {
        voterUniqueIds,
        voterData
      }
    },
    async getVoterData(voterUniqueIds, localFilters) {
      const request = this.$basedRequest().select({
        voters: [
          'id',
          'customer_id',
          'author',
          'first_name',
          'last_name',
          'unique_id',
          'created_at',
          'source',
          'nps',
          'avg_score',
          'insatisfaction_user_group_id',
          'conversation_manager_id',
          'is_insatisfaction_transfered',
          'processing_status',
          'first_treatment_action',
          'has_voter_automated_message_treatment_scheduled',
          'places_name',
          'is_renotation',
          { campaigns_id: { as: 'campaign_id'} },
          'insatisfaction_status',
          'main_verbatim_content',
          'full_trustville_states'
        ]
      }).where({
        unique_id: voterUniqueIds
      }).order(
        [localFilters.sortFilters.sortValue, localFilters.sortFilters.order, "nulls last"]
      )
      
      const voters = (await this.$resolve(request, 'getVoterData'))?.data?.voters || []

      return voters
    },
    treatSubStatusFilters(subStatus) {
      return {
        all: {
          processing_status: {
            in: ['insatisfaction_not_processed', 'conversation_need_answer', 'pending_validation']
          }
        },
        pending_validations: {
          processing_status: 'pending_validation',
        },
        new_feedbacks: {
          processing_status: 'insatisfaction_not_processed'
        },
        new_messages: {
          processing_status: 'conversation_need_answer'
        },
      }[subStatus]
    },
    assignationFilters(subStatus) {
      return {
        all: { assigned_to: 'current_user' },
        pending_validations: { pending_validations_assigned_to: 'current_user' },
        new_feedbacks: { insatisfactions_assigned_to: 'current_user' },
        new_messages: { conversations_assigned_to: 'current_user' }
      }[subStatus]
    },
    applyFilters(request, status, subStatus, treatFilters) {
      if (status == "treat") {
        const treatRequest = request.where({ ...this.treatSubStatusFilters(subStatus), treatment_flag: true })

        if (treatFilters) {
          const assignation = (treatFilters.assignation || { value: 'me' }).value

          if (assignation === 'me') {
            return treatRequest.where({
              assignation: this.assignationFilters(subStatus)
            })
          } else if (assignation === 'all') {
            return treatRequest
          } else {
            if (subStatus === 'new_feedbacks') {
              return treatRequest.where({
                lj_insatisfactions_user_group_id: assignation
              })
            }
          }
        }
        return treatRequest
      } else if (this.status == "treated") {
        return request.where({
          processing_status: 'processed',
          treatment_flag: true
        })
      } else {
        return request
      }
    },
    applyScoreFilters(request, scoreFilters) {
      if (scoreFilters?.mainScoreFilter) {
        if (scoreFilters?.mainScoreFilter === 'promoters') {
          request = request.where({ 'score_sentiment': 'positive'})
        } else if (scoreFilters?.mainScoreFilter === 'neutrals') {
          request = request.where({ 'score_sentiment': 'neutral'})
        } else if (scoreFilters?.mainScoreFilter === 'detractors') {
          request = request.where({ 'score_sentiment': 'negative'})
        }
      }

      return request
    },
    applySearchFilter(voterIdRequest, searchFilter) {
      if (searchFilter?.value && searchFilter?.value?.length > 2) {
        voterIdRequest = voterIdRequest.where({
          content: { search: searchFilter?.value }
        })
      }

      return voterIdRequest
    },
    async getVoterUniqueIds() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let voterIdRequest = this.filteredVoterRequestBase.limit(
        itemsPerPage
      ).offset(
        (page - 1) * itemsPerPage
      ).select(
        { voters: [ "id", "unique_id" ] }
      )

      const voters = (await this.$resolve(voterIdRequest))?.data?.voters || []

      return (voters.map(voter => voter.uniqueId))
    },
    votersRequestFilter(status) {
      let request = this.dashboardFilterRequest
      if (
        this.currentDashboardInsatisfactionNotificationEnabled &&
        status == 'treat' &&
        this.currentDashboardTreatVotersPeriod === 'genesis'
      ) {
        request = request.dateBetween('1970-01-01', sqlDate(this.$date().format('YYYY-MM-DD'), '23:59:59'))
      }

      if (this.localFilters?.trustvillePublicStateFilters?.value) {
        request = request.where({ trustville: { with_public_state: this.localFilters?.trustvillePublicStateFilters?.value } })
      }

      return request
    }
  }
}
