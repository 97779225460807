<template>
  <div class="product-category-tree">
    <WTreeSelect
      :semanticPresence="false"
      v-if="categoriesData" 
      :searchedOptions="searchedProductCategoryIds"
      :tree="categoryTree"
      :labelById="categoryById"
      v-model="selectedProductCategoryIds"
    >
      <template #option="{ item }">
        {{ categoryById[item.value] }}
      </template>
    </WTreeSelect>

    <v-skeleton-loader v-else type="text,text,text" class="pa-3 pt-4" />
  </div>
</template>

<script>
export default {
  name: "ProductCategoryTree",
  props: [ 'value', 'campaign', 'searchValue' ],
  data() {
    return {
    }
  },
  computed: {
    selectedProductCategoryIds: {
      get()  { 
        return this.value?.map(v => v.id)
      },
      set(productCategoryIds) { 
        const selectedProductCategories = productCategoryIds.map(productCategoryId => {
          return { 
            name: this.categoryById[productCategoryId],
            id: productCategoryId
          }
        })

        this.$emit('input', selectedProductCategories)
      }
    },
    categoryTree() {
      if (this.categoryById && this.categoriesData) {
        return this.$helpers.tree.deepSort(this.categoriesData.categoryTree, (node) => {
          return this.categoryById[Number(node)]?.toLowerCase()
        })
      }
    },
    categoryById() {
      return this.categoriesData?.categoryById
    },
    searchedProductCategoryIds() {
      if (this.searchValue?.length > 2) {
        const matchPatterns = this.searchValue.split(" ").map(v => this.$unaccent(v)).filter(v => v !== "").map(v => new RegExp(`${v}`, 'ig'))
        const matchPattern = new RegExp(`${this.searchValue}`, 'ig')
        const searchedOptions = []

        for (const [productCategoryId, label] of Object.entries(this.categoryById)) {
          if (matchPatterns.every(matchPattern => this.$unaccent(label)?.match(matchPattern))) {
            searchedOptions.push(productCategoryId)
          }
        }

        return searchedOptions
      }
    }
  },
  asyncComputed: {
    categoriesData: {
      async get() {
        const request = this.$basedRequest().select({
          campaigns: [ 
            { product_category_tree: { as: "categoryTree" } },
            { product_category_label_by_id: { as: "categoryById" } }
          ],
        }).where(
          { id: this.campaign.id }
        )

        const response = (await this.$resolve(request)).first()
        return response
      },
      lazy: true
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
