<template>
  <w-drop-down-menu
    v-model="optionsMenuSelectedValues"
    :showGroupTitle="wDropDownShowGroupTitle"
    :items="optionsMenuItems"
    :listItemHeightStyle="{ minHeight: '18px'}"
    class="advanced-filter-menu"
  >
    <template v-slot:activator-content="{ open }">
      <div class="d-flex align-center">
        <v-icon small>{{ filterSelectedIcon }}</v-icon>
        <v-icon v-if="open" small>mdi-chevron-up</v-icon>
        <v-icon v-else small>mdi-chevron-down</v-icon>
        <v-tooltip top v-if="localFilters?.trustvillePublicStateFilters?.value">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <WBadgeOutlined class="pl-1" small content="1"/>
            </span>
          </template>
          <span>{{ trustvilleFilterTooltipLabel }}</span>
        </v-tooltip>
      </div>
    </template>
  </w-drop-down-menu>
</template>


<script>
  import { mapGetters } from 'vuex'
  import _isEqual from 'lodash/isEqual'

  export default {
    name: "AvdancedFilter",
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        localFilters: { ...this.value }
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardRankingRelatedColumn',
        'dashboardFilterDatesText',
        'currentDashboardTreatVotersPeriod',
        'isCurrentDashboardTrustvilleEligible',
        'currentDashboardInsatisfactionNotificationEnabled',
        'datesScope'
      ]),
      wDropDownShowGroupTitle() {
        return this.optionsMenuItems.filter(group => group.id != 'sorting').length > 0
      },
      filterSelectedIcon() {
        const sortingItems = this.optionsMenuItems.find((item) => item.id === 'sorting')
        const item = sortingItems.children.find((item) => item.value.key == this.optionsMenuSelectedValues[0].key)

        return item.icon
      },
      trustvilleFilterTooltipLabel() {
        let label = null;
        switch (this.localFilters?.trustvillePublicStateFilters?.value) {
          case 'soon_online':
            label = this.$t('trustville_status_soon_online');
            break;
          case 'online':
            label = this.$t('trustville_status_online');
            break;
          case 'unpublished':
            label = this.$t('trustville_status_not_online');
            break;
          case 'under_moderation':
            label = this.$t('trustville_status_under_moderation');
            break;
          default:
            label = this.$t('trustville_status_label_online_all');
        }

        return `Trustville - ${label}`;
      },
      optionsMenuSelectedValues: {
        get() {
          return [
            this.localFilters.sortFilters,
            this.localFilters.trustvillePublicStateFilters,
            this.currentDashboardTreatVotersPeriod
          ]
        },
        set(newValues) {
          Object.values(newValues).forEach((newValue) => {
            if (newValue?.id === "sorting") {
              if (!_isEqual(this.localFilters.sortFilters, newValue)) {
                this.localFilters.sortFilters = newValue;
              }
            } else if (newValue?.id === "trustville_public_status") {
              if (!_isEqual(this.localFilters.trustvillePublicStateFilters, newValue)) {
                this.localFilters.trustvillePublicStateFilters = newValue;
              }
            } else if (newValue?.id === "sharing_date") {
              this.$store.dispatch("setTreatVotersPeriod", newValue.value);
            }
          });
        },
      },
      optionsMenuItems() {
        const items =  [
          {
            id: 'sorting',
            title: this.$t('sorting'),
            children: [
              {
                title: this.$t('sort_filter_newer'),
                icon: 'mdi-sort-clock-ascending-outline',
                value: { sortValue: 'created_at', order: 'desc', id: 'sorting', key: "created_asc" }
              },
              {
                title: this.$t('sort_filter_older'),
                icon: 'mdi-sort-clock-descending-outline',
                value: { sortValue: 'created_at', order: 'asc', id: 'sorting', key: "created_desc" }
              },
              {
                title: this.$t('sort_filter_most_negative'),
                icon: 'mdi-sort-numeric-descending',
                value: { sortValue: this.currentDashboardRankingRelatedColumn, order: 'asc', id: 'sorting', key: "negative" }
              },
              {
                title: this.$t('sort_filter_most_positive'),
                icon: 'mdi-sort-numeric-ascending',
                value: { sortValue: this.currentDashboardRankingRelatedColumn, order: 'desc', id: 'sorting', key: "positive" }
              }
            ]
          },
        ]

        if (this.isCurrentDashboardTrustvilleEligible) {
          items.push(
            {
              id: 'trustville_public_status',
              title: this.$t('Trustville'),
              children: [
                {
                  title: this.$t('trustville_status_online_all'),
                  value: { value: null, id: 'trustville_public_status' }
                },
                {
                  title: this.$t('trustville_status_online'),
                  value: { value: "online", id: 'trustville_public_status' }
                },
                {
                  title: this.$t('trustville_status_not_online'),
                  value: { value: 'unpublished', id: 'trustville_public_status' }
                },
                {
                  title: this.$t('trustville_status_soon_online'),
                  value: { value: "soon_online", id: 'trustville_public_status' }
                },
                {
                  title: this.$t('trustville_status_under_moderation'),
                  value: { value: "under_moderation", id: 'trustville_public_status' }
                }
              ]
            }
          )
        }

        if (this.showTreatVotersPeriodSelect) {
          items.push(
            {
              id: 'sharing_date',
              title: this.$t('sharing_date'),
              children: [
                {
                  title: this.$t('dateFilter.genesis'),
                  value: { value: "genesis", id: 'sharing_date' }
                },
                {
                  title: this.dashboardFilterDatesText,
                  value: { value: "custom", id: 'sharing_date' }
                }
              ]
            }
          )
        }

        return items
      },
      showTreatVotersPeriodSelect() {
        return this.currentDashboardInsatisfactionNotificationEnabled && this.datesScope.key !== 'genesis'
      },
    },
    watch: {
      value: {
        deep: true,
        handler(newVal) {
          if (!_isEqual(newVal, this.localFilters)) {
            this.localFilters = { ...newVal };
          }
        },
      },
      localFilters: {
        deep: true,
        handler(newFilters) {
          if (!_isEqual(newFilters, this.value)) {
            this.$emit("input", newFilters);
          }
        },
      },
    },
  }
</script>
