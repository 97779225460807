import ChartsDefaultOptionsMixin from './charts_default_options_mixin'
import deepmerge from '@shared/helpers/deepmerge'

export default {
  mixins: [
    ChartsDefaultOptionsMixin
  ],
  computed: {
    chartDefaultOptions() {
      const _this = this
      let options = {
        chart: {
          type: 'areaspline'
        },
        xAxis: {
          min: 1
        },
        yAxis: {
          title: { step: 2 },
          min: 1,
          endOnTick: false,
          labels: {
            formatter: function () {
              return _this.yAxisLabelsFormatter(this.value) || this.value
            }
          }
        }
      }

      return deepmerge(this.sharedDefaultOptions, options)
    }
  }
}