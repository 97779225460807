<template>
  <WStatsWidget
    :cols="cols"
    :subtitle="displayedSubtitle"
    :title="title"
    :loading="loading"
    :empty="empty"
    contentPadding="0.75em 0.75em 0 0.75em"
    contentWidth="100%"
  >
    <template #title>
      <slot name="title">
        {{ title || $t('voter_volume_per_source') }}
      </slot>
    </template>

    <template #options>
      <div class="d-flex align-center">
        <div>
          <slot name="options">
          </slot>
        </div>
        <div>
          <w-drop-down-menu
            data-html2canvas-ignore
            :items="exportOptions"
            icon="mdi-download">
          </w-drop-down-menu>
        </div>
      </div>
    </template>

    <template #content>
      <div class="chart-timeline-loading" v-if="loading">
        <v-progress-linear indeterminate class="c-petrol-blue"></v-progress-linear>
      </div>
      <div class="chart-container" ref="voter-volume-per-period-div-chart">
        <Chart :options="chartOptions" ref="chart" />
      </div>
      <div class="chart-time-navigation">
        <WTimeNavigation
          :options="timeNavigationOptions"
          :refreshData="refreshData"
        />
      </div>
    </template>

    <template #footer-left />
    <template #footer-right />
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Chart } from 'highcharts-vue'
  import chartToPng from "@shared/helpers/chart-to-png"
  import Pdf from '@shared/helpers/exportToPdf/pdf'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import ColumnChartsDefaultOptionsMixin from '@statistics/shared/ChartsDefaultOptions/column_charts_default_parameters_mixin'
  import deepmerge from '@shared/helpers/deepmerge'
  import { COLORS_BY_SOURCE, SOURCE_MAPPING } from '@statistics/shared/voters_sources_constants'
  import TimeNavigationMixin from '@statistics/shared/time_navigation_mixin'

  export default {
    name: "VoterVolumePerPeriodWidget",
    components: {
      Chart
    },
    props: {
      title: { type: String, required: false },
      basedRequest: { type: Object },
      cols: { required: false, default: 12 }
    },
    data() {
      return {
        loading: true,
        triggerChartOptionsComputing: 0
      }
    },
    mixins: [
      WidgetMixin,
      ColumnChartsDefaultOptionsMixin,
      TimeNavigationMixin
    ],
    computed: {
      ...mapGetters([
        'datesScope',
        'currentDashboard',
        'dashboardFilterUnit',
        'dashboardFilterBase64'
      ]),
      exportOptions() {
        return [
          { title: 'PNG', onClick: this.exportToPng },
          { title: 'PDF', onClick: this.exportToPdf }
        ]
      },
      groupedBasedRequest() {
        return this.basedRequest.group([
          this.basedGroup,
          'source'
        ])
      },
      basedGroup() {
        switch (this.dashboardFilterUnit) {
          case 'day':
            return { created_at: { mod: 'DATE_TRUNC_DAY', cast: 'date' } }
          case 'week':
            return { created_at: { mod: 'DATE_TRUNC_WEEK', cast: 'date' } }
          case 'month':
            return { created_at: { mod: 'DATE_TRUNC_MONTH', cast: 'date' } }
          case 'quarter':
            return { created_at: { mod: 'DATE_TRUNC_QUARTER', cast: 'date' } }
          case 'year':
            return { created_at: { mod: 'DATE_TRUNC_YEAR', cast: 'date' } }
        }
      },
      timeNavigationOptions() {
        return {
          filterDates: this.dashboardFilterDates,
          unit: this.dashboardFilterUnit,
          from: this.defaultFromDate,
          to: this.defaultToDate,
          maxDate: this.maxDate,
          minDate: this.minDate,
          periodNumber: this.periodNumber
        }
      }
    },
    asyncComputed: {
      async chartOptions() {
        this.triggerChartOptionsComputing
        this.loading = true

        const chartData = await this.chartData()
        const labels = chartData.labels.map(label => Object.values(label[1])[0])
        const series = chartData.series.map(serie => {
          let name = serie[1]['name']
          let data = serie[1]['data'].map(element => (element && element['countDistinctId']) || 0)

          return {
            type: 'column',
            name: this.$t(`source.${name}`),
            color: COLORS_BY_SOURCE[name],
            data: data
          }
        })

        this.loading = false

        const chartLocalOptions = {
          xAxis: {
            categories: labels
          },
          plotOptions: {
            series: {
              thickness: 2,
              borderRadius: '15%',
              borderWidth: 0,
              borderColor: 'white',
              size: '100%',
              innerSize: '100%',
              stacking: 'normal'
            },
            column: {
              groupPadding: 0.05
            },
          },
          series: series
        }

        return deepmerge(this.chartDefaultOptions, chartLocalOptions)
      },
      empty() {
          return !(this.chartOptions?.series?.length > 0)
      }
    },
    methods: {
      exportFileName() {
        const pageName = this.$t(`voter_volume_per_source`);
        const campaignName = this.currentDashboard.name;
        const period = this.dashboardFilterDates.text;
        const date = this.$date().format('ddd DD MMM HH_mm');

        return `${pageName} - ${campaignName} - ${period} - ${date}`;
      },
      exportToPng() {
        this.$store.dispatch("notifyInfo");
        const chart = this.$refs.chart.chart;
        const fileName = this.exportFileName()
        const title = this.title

        chartToPng(chart, { width: 700, fileName, title });
      },
      async exportToPdf() {
        this.$store.dispatch("notifyInfo");
        const chart = this.$refs.chart.chart;
        const fileName = this.exportFileName()
        const title = this.title

        const pdf = new Pdf({
          defaultBodyMargin: { left: 40, top: 40 }
        });

        await pdf.addPage()

        await pdf.addRow({}, async (row) => {
          await row.addCol({ width: '12' }, async (col) => {
            await col.addText(title, { color: "#212121", font: { style: 'semi-bold' } });
          });
        })

        await pdf.addRow({ marginTop: 20 }, async (row) => {
          await row.addCol({ width: '12' }, async (col) => {
            await col.addChart(chart, { width: 700 });
          });
        });

        pdf.save(fileName);
      },
      async chartData() {
        const dateBegin = this.timeNavigationOptions.from.format("YYYY-MM-DD")
        const dateEnd = this.timeNavigationOptions.to.format("YYYY-MM-DD")
        const response = await this.groupedBasedRequest.dateBetween(dateBegin, dateEnd).resolve("VoterVolumePerPeriod",
          {
            tickNumber: 1,
            tickUnit: this.dashboardFilterUnit,
            timeSerieParams: {
              limit: this.tickLimit,
              date_begin: dateBegin,
              date_end: dateEnd
            }
          },
          "time_series"
        )

        return {
          labels: Object.entries(response.data.labels),
          series: Object.entries(response.data.series)
        }
      },
      refreshData() {
        this.triggerChartOptionsComputing++
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .chart-time-navigation
    position: relative
    top: -25px
    left: -4px
</style>