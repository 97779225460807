<template>
  <div class="basic-w-tree-select">
    <v-card v-click-outside="onClickOutside">
      <v-form v-model="formIsValid" ref="userForm" @input="emitPlaceGroupFormIsValid">
        <v-card-text class="pa-0">
          <div @click="toggleDisplayWTreeSelect" @click:outside="toggleHideSelectedPlacesSection">
            <v-text-field
              ref="placeGroupTextFieldRef"
              outlined
              dense
              clearable
              hide-details="auto"
              :error-messages="errorMessages"
              v-model="searchValue"
              @click:clear="onSearch"
              @focus="onFocusPlaceGroupTextfield"
              @keydown="onKeydown"
              :rules="[placeGroupOrPlaceIdsRule]"
            >
              <template v-slot:label>
                <span class="f-13">{{ $t('collaborator_sidebar_place_groups') }}</span>
              </template>
              <template v-slot:append>
                <v-icon
                  :class="{
                    'rotate-up': displayWTreeSelect,
                    'c-primary': displayWTreeSelect,
                    'rotate-down': !displayWTreeSelect
                  }"
                >
                  mdi-menu-down
                </v-icon>
              </template>
            </v-text-field>
          </div>
          <div
              class="allSelectedPlaceList pb-8"
              v-if="selectedPlaceGroupsOrPlaceIds?.length > 0 && labelById && !hideSelectedPlacesSection"
          >
            <template v-for="(selectedPlaceGroupOrPlaceId, idx) in selectedPlaceGroupsOrPlaceIds">
              <v-chip
                class="mt-1 mx-1 f-12"
                close
                column
                dense
                @click:close="removeSelectedPlaceGroupOrPlaceId(idx)"
              >
                <span>
                  <v-icon small class="pr-1 mr-1">{{ placeGroupsOrPlacesIcon(selectedPlaceGroupOrPlaceId) }}</v-icon>

                  {{ labelById[selectedPlaceGroupOrPlaceId] }}
                </span>
              </v-chip>
            </template>
            <hr class="border-bodygrey mt-1" />
          </div>
          <WTreeSelect
              :semanticPresence="true"
              :tree="labelByMainCategory"
              v-model="selectedPlaceGroupsOrPlaceIds"
              :searchedOptions="searchedPlaceGroupsOrPlaces"
              v-if="labelByMainCategory && displayWTreeSelect"
              class="perimeters-select"
              :style="{ maxHeight: computedMaxHeight }"
              :labelById="labelById"
          >
            <template #option="{ item }">
              <span>{{ labelById[item.value] }}</span>
            </template>
          </WTreeSelect>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  import _debounce from "lodash/debounce"

  export default {
    name: 'PlaceGroupWTreeSelect',
    props: ['placeGroupsList', 'value', 'errorMessages', 'errorCounter'],
    data() {
      return {
        formIsValid: false,
        searchedPlaceGroupsOrPlaces: undefined,
        selectedPlaceGroupsOrPlaceIds: [],
        searchValue: "",
        placeGroupTextFieldFocusData: false,
        displayWTreeSelect: false,
        hideSelectedPlacesSection: false,
      }
    },
    methods: {
      emitPlaceGroupFormIsValid() {
        this.$emit('updatePlaceGroupFormIsValid', this.formIsValid)
      },
      resetForm() {
        this.$refs.userForm.reset()
        this.$refs.userForm.resetValidation()
      },
      toggleHideSelectedPlacesSection() {
        this.hideSelectedPlacesSection = !this.hideSelectedPlacesSection;
      },
      validateForm() {
        this.isValid = this.$refs.userForm.validate()
        return this.formIsValid
      },
      onFocusPlaceGroupTextfield() {
        this.placeGroupTextFieldFocusData = true
      },
      removeSelectedPlaceGroupOrPlaceId(index) {
        this.selectedPlaceGroupsOrPlaceIds.splice(index, 1)
      },
      placeGroupsOrPlacesIcon(item) {
        if (item.includes("brand")) {
          return "mdi-map-marker-multiple-outline"
        } else if (item.includes("place")) {
          return "mdi-map-marker"
        } else {
          return "mdi-web"
        }
      },
      placeGroupOrPlaceIdsRule() {
        return this.selectedPlaceGroupsOrPlaceIds.length != 0 || this.$t('collaborator_sidebar_errors_required_field')
      },
      toggleDisplayWTreeSelect() {
        if (this.placeGroupTextFieldFocusData) {
          this.displayWTreeSelect = !this.displayWTreeSelect
        }

        this.toggleHideSelectedPlacesSection()
      },
      onClickOutside() {
        if (this.hideSelectedPlacesSection) {
          this.toggleHideSelectedPlacesSection()
        }
        this.displayWTreeSelect = false
      },
      onKeydown(event) {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
        this.onSearch()
      },
      onSearch: _debounce(function() {
        this.displayWTreeSelect = true
        if (this.searchValue?.length > 2) {
          const matchPatterns = this.searchValue.split(" ").map(v => this.$unaccent(v)).filter(v => v !== "").map(v => new RegExp(`${v}`, 'ig'))
          const matchPattern = new RegExp(`${this.searchValue}`, 'ig')
          const searchedPlaceGroupsOrPlaces = []

          for (const [productGroupId, label] of Object.entries(this.labelById)) {
            if (matchPatterns.every(matchPattern => this.$unaccent(label)?.match(matchPattern))) {
              searchedPlaceGroupsOrPlaces.push(productGroupId)
            }
          }

          this.searchedPlaceGroupsOrPlaces = searchedPlaceGroupsOrPlaces
        } else {
          this.searchedPlaceGroupsOrPlaces = null
        }
      }, 200),
      orderByLabel(formattedData) {
        const sortedData = {};

        // Helper function to get label by ID
        const getLabelById = (id) => this.placeGroupsList?.labelById[id] || '';

        // Recursive function to sort each level
        function sortObject(obj) {
          if (Array.isArray(obj)) {
            // Sort array elements by their labels
            return obj.slice().sort((a, b) => {
              const labelA = getLabelById(a);
              const labelB = getLabelById(b);
              return labelA.localeCompare(labelB);
            });
          } else if (typeof obj === 'object' && obj !== null) {
            const sorted = {};
            Object.keys(obj)
                .sort((a, b) => {
                  const labelA = getLabelById(a);
                  const labelB = getLabelById(b);
                  return labelA.localeCompare(labelB);
                })
                .forEach((key) => {
                  sorted[key] = sortObject(obj[key]);
                });
            return sorted;
          }
          return obj;
        }

        if (formattedData.allPlacesData) {
          sortedData.allPlacesData = sortObject(formattedData.allPlacesData);
        }

        // Sort the remaining keys excluding `allPlaces`
        Object.keys(formattedData)
            .filter((key) => key !== 'allPlacesData')
            .sort((a, b) => {
              const labelA = getLabelById(a);
              const labelB = getLabelById(b);
              return labelA.localeCompare(labelB);
            })
            .forEach((key) => {
              sortedData[key] = sortObject(formattedData[key]);
            });

        return sortedData;
      }
    },
    computed: {
      tree() {
        let tree = this.placeGroupsList?.tree
        if (tree) {        
          const allPlacesObject = { allPlaces: tree?.allPlaces }

          delete tree.allPlace
          tree = this.$helpers.tree.deepSort(tree, v => this.labelById[v])
          tree = { ...allPlacesObject, ...tree }
          return tree
        }
      },
      errorCounterWithOffset() {
        return this.errorCounter() * 10;
      },
      labelById() {
        return this.placeGroupsList?.labelById
      },
      labelByMainCategory() {
        let data = this.placeGroupsList?.tree;
        if (!data) return
        const formatted = {};
        for (const key in data) {
          if (key === 'semanticValues') continue;

          let [prefix, id] = key.split('-');
          if (prefix === 'allPlaces') {
            prefix = 'allPlacesData';
          } else {
            prefix = data['semanticValues'][prefix];
          }
          if (!formatted[prefix]) {
            formatted[prefix] = {};
          }
          formatted[prefix][key] = data[key];
        }
        return this.orderByLabel(formatted);
      },
      computedMaxHeight() {
        const dynamicValue = this.errorCounter() * 30;
        const divHeight = document.querySelector('.collaborators-edit-sidebar .v-navigation-drawer').offsetHeight

        if (dynamicValue === 0) {
          return `calc(${divHeight}px - 340px)`;
        }
        return `calc(${divHeight}px - 340px - ${dynamicValue}px)`;
      }
    },
    watch: {
      selectedPlaceGroupsOrPlaceIds() {
        this.$refs.userForm.validate()
        this.$emit('input', this.selectedPlaceGroupsOrPlaceIds)
      },
      value() {
        this.selectedPlaceGroupsOrPlaceIds = this.value
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .perimeters-select, .allSelectedPlaceList
    margin-bottom: 55px
    overflow: scroll
  .rotate-up
    transition: transform 0.3s
    transform: rotate(180deg)

  .rotate-down
    transition: transform 0.3s
    transform: rotate(0deg)

  .basic-w-tree-select
    .v-text-field__details
      margin-bottom: 0px !important
</style>
