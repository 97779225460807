<template>
  <WStatsWidget
    v-if="topic === undefined"
    :title="title"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <EvolutionWidget
    v-else
    :title="title"
    :basedRequest="basedRequest"
    :campaign="campaign"
    :indicatorAlias="indicatorAlias"
    :avgScoreScale="campaign.avgScoreScale"
    :yAxisLabelsFormatter="yAxisLabelsFormatter"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import EvolutionWidget from '@statistics/shared/widgets/EvolutionWidget'
  import TopicsMixin from '@statistics/shared/topics_mixin'

  export default {
    name: "TopicEvolutionWidget",
    props: {
      campaign: { type: Object, required: true },
      topic: { type: Object },
    },
    mixins: [
      TopicsMixin
    ],
    components: {
      EvolutionWidget
    },
    methods: {
      yAxisLabelsFormatter(yValue) {
        return this.topicsMixin_isDisplayTypeScale(this.topic) ? this.topic.rateLabelsHash[yValue] : null
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
      ]),
      basedRequest() {
        let request = this.dashboardFilterRequest.select({
          voters: [
            { avg_topic_: { topic_id: this.topic.id, as: this.indicatorAlias } },
            { nb_review_topic_: { topic_id: this.topic.id, as: 'nbReview' } }
          ]
        })

        if (this.campaign) {
          request = request.where({ campaign_id: this.campaign.id })
        }
        return request
      },
      indicatorAlias() {
        return 'score'
      },
      title() {
        return this.topic == undefined ?
          this.$t('widget_evolution_title') :
          `${this.$t('widget_evolution_title')} - ${this.topic.name}`
      }
    }
  }
</script>
