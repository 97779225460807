<template>
  <v-badge 
    v-if="shouldDisplay"
    :color="badgeAttributes.color"
    class="voter-processing-status-badge d-flex"
    inline 
    tile
  >
    <template #badge>
      <span class="v-badge-content d-flex text-truncate font-weight-bold">
        <v-tooltip top :disabled="!badgeAttributes.destination">
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="v-badge-content font-weight-bold d-flex text-truncate">
              <span v-if="badgeAttributes.context">{{ badgeAttributes.context }}&nbsp;</span>
              <span class="text-truncate" v-if="badgeAttributes.destination">{{ badgeAttributes.destination }}</span>
            </div>
          </template>
          <span>{{ badgeAttributes.destination }}</span>
        </v-tooltip>
      </span>
    </template>
  </v-badge>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "VoterProcessingStatusBadge",
    props: {
      voterNeedsProcessing: { required: true, type: Boolean },
      voterProcessingStatus: { required: false },
      isInsatisfactionForCurrentGroup: { type: Boolean, required: true, default: false },
      transferedToUserGroupName: { type: String, required: false, default: "" },
      transferedToThirdPartyEmail: { type: String, required: false, default: "" },
      currentUserNeedValidationForCampaign: { required: true, type: Boolean, default: false },
      isConversationManagedByCurrentUser: { required: false, type: Boolean, default: false},
      voterConversationManagerEmail: { required: false, type: String, default: "" }
    },
    computed: {
      ...mapGetters([
        'currentVoterHasVoterAutomatedMessageTreatmentScheduled'
      ]),
      shouldDisplay() {
        if (this.voterProcessingStatus) {
          return true
        } else {
          if (this.currentVoterHasVoterAutomatedMessageTreatmentScheduled) {
            return true
          }
        }

        return false
      },
      badgeAttributes() {
        let context
        let destination
        let color

        if (this.voterNeedsProcessing) {
          if (this.isVoterPendingForValidationForAnotherUser) {
            context = this.$t('w_insatisfaction_badge_pending_validation')
            color = 'lightgrey'
          } else if (this.isVoterNeedAnswerFromAnotherUser) {
            context = this.$t('w_insatisfaction_badge_status_to_process')
            destination = this.voterConversationManagerEmail
            color = 'lightgrey'
          } else if (this.isVoterInsatisfactionForAnother) {
            context = this.$t('w_insatisfaction_badge_status_to_process')
            destination = this.transferedToUserGroupName || this.transferedToThirdPartyEmail
            color = 'lightgrey'
          } else {
            context = this.notProcessStatusLabel
            color = this.notProcessStatusColor
          }
        } else if (this.isVoterProcessed) {
          context = this.$t('w_insatisfaction_badge_status_processed')
          color = 'success'
        } else if (this.currentVoterHasVoterAutomatedMessageTreatmentScheduled) {
          context = this.$t('w_insatisfaction_badge_treat_by_robot')
          color = 'lightgrey'
        } else if (this.isVoterArchived) {
          context = this.$t('w_insatisfaction_badge_status_archived')
          color = 'lightgrey'
        }

        return { context, destination, color }
      },
      isVoterPendingForValidationForAnotherUser() {
        return this.voterProcessingStatus === 'pending_validation' &&
          this.currentUserNeedValidationForCampaign
      },
      isVoterNeedAnswerFromAnotherUser() {
        return this.voterProcessingStatus === 'conversation_need_answer' &&
          !this.isConversationManagedByCurrentUser
      },
      isVoterInsatisfactionForAnother() {
        return this.transferedToUserGroupName ||
          this.transferedToThirdPartyEmail
      },
      isVoterProcessed() {
        return this.voterProcessingStatus === 'processed'
      },
      isVoterArchived() {
        return this.voterProcessingStatus === 'insatisfaction_archived'
      },
      notProcessStatusLabel() {
        return this.currentVoterHasVoterAutomatedMessageTreatmentScheduled ?
          this.$t('w_insatisfaction_badge_treat_by_robot') :
          this.$t('w_insatisfaction_badge_status_not_process')
      },
      notProcessStatusColor() {
        return this.currentVoterHasVoterAutomatedMessageTreatmentScheduled ?
          'lightgrey' :
          'error'
      }
    }
  }
</script>

<style lang="stylus">
  .voter-processing-status-badge
    .v-badge__badge
      height: 22px !important
      display: flex
      border-radius: 4px
    .v-badge-content
      max-width: 225px
      font-size: 13px !important
</style>
