<template>
  <v-container fluid class="reportings">
    <v-row no-gutters>
      <v-col>
        <v-data-table
          :loading="!reportings"
          :headers="headers"
          :items="formatedReportings"
          group-by="year"
          hide-default-footer
          disable-pagination
        >
          <template #group.header='{ group }'>
            <td :colspan="headers.length">
              {{ -group }}
            </td>
          </template>

          <template #item.createdAt='{ value }'>
            <WDate :date="value" />
          </template>

          <template #item.period='{ item }'>
             <template v-if="item.period == 'yearly'">
                <v-badge inline color="warning"><template #badge>{{ $t('year')}} {{ item.dateBegin | year }}</template></v-badge>
            </template>
 
            <template v-if="item.period == 'weekly'">
              {{ item.dateBegin | week }}
            </template>

            <template v-if="item.period == 'monthly'">
              <v-badge inline color="primary"><template #badge>{{ item.dateEnd | month | capitalize }}</template></v-badge>
            </template>

            <template v-if="item.period == 'quarterly'">
              <v-badge inline color="success"><template #badge>{{ item.dateEnd |quarterYear }}</template></v-badge>
            </template>
          </template>

          <template #item.previewUrl='{ value }'>
            <div class="text-center">
              <v-btn v-if="value" icon :href="value" target="_blank" icon><v-icon small>mdi-eye</v-icon></v-btn>
            </div>
          </template>

          <template #item.downloadPdfUrl='{ value }'>
            <div class="text-center">
              <v-btn v-if="value" icon :href="value" target="_blank" icon><v-icon small>mdi-download</v-icon></v-btn>
            </div>
          </template>

          <template #item.downloadZipUrl='{ value }'>
            <div class="text-center">
              <v-btn v-if="value" icon :href="value" target="_blank" icon><v-icon small>mdi-download</v-icon></v-btn>
            </div>
          </template>

          <template #item.downloadVerbatimUrl='{ value }'>
            <div class="text-center">
              <v-btn v-if="value" icon :href="value" target="_blank" icon><v-icon small>mdi-download</v-icon></v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Reportings",
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'isDashboardMulti',
        'currentDashboardCampaignIds',
        'currentDashboardCampaignById'
      ]),
      formatedReportings() {
        return (this.reportings?.map(reporting => {
          window.reporting = reporting
          return {
            ...reporting,
            ...{ 
              campaignName: this.currentDashboardCampaignById[reporting.campaignId]?.name,
              year: -Number(reporting.dateBegin.slice(0, 4))
            }
          }
        }) || [])
      },
      headers() {
        let headers = [
          { text: 'Période', value: 'period', sortable: false },
        ]

        if (this.isDashboardMulti) {
          headers.splice(2, 0, { text: this.$helpers.string.capitalize(this.$i18n.t("campaign")), value: 'campaignName', sortable: false })
        }

        if (this.isColumnFilled(this.formatedReportings, 'previewUrl')) {
          headers.splice(3, 0, { text: this.$i18n.t("reporting_email"), value: 'previewUrl', sortable: false })
        }

        if (this.isColumnFilled(this.formatedReportings, 'downloadPdfUrl')) {
          headers.splice(4, 0, { text: this.$i18n.t("reporting_pdf"), value: 'downloadPdfUrl', sortable: false })
        }

        if (this.isColumnFilled(this.formatedReportings, 'downloadZipUrl')) {
          headers.splice(5, 0, { text: this.$i18n.t("reporting_pdf_and_email"), value: 'downloadZipUrl', sortable: false })
        }

        if (this.isColumnFilled(this.formatedReportings, 'downloadVerbatimUrl')) {
          headers.splice(6, 0, { text: this.$i18n.t("reporting_verbatim"), value: 'downloadVerbatimUrl', sortable: false })
        }

        return headers
      },
    },
    asyncComputed: {
      async reportings() {
        const request = this.$basedRequest().select({ reportings: [
          { created_at: { as: 'createdAt' } },
          { period: { as: 'period' } },
          { campaign_id: { as: 'campaignId' } },
          { date_begin: { as: 'dateBegin' } },
          { date_end: { as: 'dateEnd' } },
          { email_preview_url: { as: 'previewUrl' } },
          { download_pdf_url: { as: 'downloadPdfUrl' } },
          { download_zip_url: { as: 'downloadZipUrl' } },
          { download_verbatim_url: { as: 'downloadVerbatimUrl' } }
        ]}).where({ 
          campaign_id: this.currentDashboardCampaignIds 
        }).scoped(['ready']).order(
          ['created_at', 'desc']
        )

        const result = await this.$resolve(request)

        return result?.data?.reportings || []
      }
    },
    methods: {
      isColumnFilled(formatedReportings, column) {
        return formatedReportings?.find((r) => r[column])
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'
      
  .reportings
    padding-top: "calc(%s + 15px)" % $filters-height
</style>