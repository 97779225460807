<template>
  <div v-if="tree">
    <div v-for="(value, key) in tree" :key="key" class="level">

      <WTreeSelectCheckbox
          :semanticPresence="false"
          :item="items[`${node(key, value, tree)}_${level}`]"
          @change="toggle"
          @expand="expand"
          :level="level"
      >
        <template #label="{ item }">
          <slot name="option" :item="item">
            <span class="fs-13 c-primary">{{ labelById[item.value]  }}</span>
          </slot>
        </template>

        <template #expand="{ item }">
          <slot name="expand" :item="item"></slot>
        </template>
      </WTreeSelectCheckbox>

      <!-- Recursively render child levels -->
      <RecursiveTree
          v-if="hasSubLevel(key, value, tree)"
          :tree="value"
          :items="items"
          :level="level + 1"
          :toggle="toggle"
          :expand="expand"
          :hasSubLevel="hasSubLevel"
          :node="node"
          :labelById="labelById"
      />
    </div>
  </div>
</template>

<script>
import WTreeSelectCheckbox from "./WTreeSelectCheckbox"

export default {
  name: "RecursiveTree",
  props: {
    hasSubLevel: {
      type: Function,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
    expand: {
      type: Function,
      required: true,
    },
    tree: {
      type: [Object, Array],
      required: true,
      validator(value) {
        return typeof value === "object" || Array.isArray(value);
      },
    },
    labelById: {
      type: [Object, Array],
      required: true,
    },
    items: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    node: {
      type: Function,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  components: {
    WTreeSelectCheckbox,
  }
};
</script>

<style scoped>
.level {
  margin-left: 20px;
}
</style>
