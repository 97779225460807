<template>
  <span class="d-flex">
    <span class="outlined-badge" :class="{ 'small': small }">
      <span class="badge-content" :class="{ 'fs-9': small, 'fs-14': !small }">{{ content }}</span>
      <slot></slot>
    </span>
  </span>
</template>

<script>
  export default {
    name: "WBadgeOutlined",
    props: {
      content: {
        type: [String, Number],
        required: true,
      },
      small: {
        type: Boolean,
        default: false,
      }
    },
  };
</script>

<style lang="stylus">
  .outlined-badge
    display: inline-flex
    align-items: center
    justify-content: center
    border: 1px solid #b224ef
    color: #b224ef
    border-radius: 50%
    padding: 0
    height: 20px
    width: 20px
    box-sizing: border-box

    &.small
      height: 14px
      width: 14px

  .badge-content
    display: flex
    align-items: center
    justify-content: center
    margin: 0
    padding-left: 1px
</style>
