import htmlToPng from '@shared/helpers/html-to-png.js'
import chartToPng from "@shared/helpers/chart-to-png"
import Pdf from '@shared/helpers/exportToPdf/pdf.js'

export default {
  computed: {
    exportFilename() {
      return [this.title, this.displayedSubtitle].join('-')
    },
    displayedSubtitle() {
      return this.subtitle || this.defaultSubtitle
    },
    defaultSubtitle() {
      return this.currentDashboardScopedCampaigns?.length > 1 && this.campaign ?
        `${this.dashboardFilterDates.text} - ${this.campaign.name}` :
        this.dashboardFilterDates.text
    },
    exportOptions() {
      return [
        { title: 'PNG', onClick: this.exportToPng },
        { title: 'PDF', onClick: this.exportToPdf }
      ]
    },
    exportableChartElement() {
      return (this.$refs?.timelineChart || this.$refs.chart.chart)
    },
    exportableHtmlElement() {
      return this.$refs.widget
    },
    exportableElement() {
      return this.exportableChartElement || this.exportableHtmlElement
    },
  },
  methods: {
    notifyInfoExport() {
      this.$store.dispatch("notifyInfo")
    },
    isExportableElementChart() {
      return this.exportableChartElement != undefined
    },
    isExportableElementHTML() {
      return this.exportableHtmlElement != undefined
    },
    exportToPng() {
      this.notifyInfoExport()

      if (this.isExportableElementChart()) {
        this.exportChartToPng()
      } else if (this.isExportableElementHTML()) {
        this.exportHtmlToPng()
      } else {
        throw new Error("Unknown export type")
      }
    },
    exportChartToPng() {
      chartToPng(this.exportableElement.$refs.chart.chart, {
        width: 700,
        fileName: this.exportFilename,
        title: this.title,
        subtitle: this.defaultSubtitle
      })
    },
    exportHtmlToPng() {
      htmlToPng(this.exportableElement.$el, `${this.exportFilename}.png`, { windowWidth: 1300 })
    },
    async exportToPdf() {
      this.notifyInfoExport()
      if (this.isExportableElementChart()) {
        this.exportChartToPdf()
      } else if (this.isExportableElementHTML()) {
        this.exportHtmlToPdf()
      } else {
        throw new Error("Unknown export type")
      }
    },
    async exportChartToPdf() {
      this.exportableChartElement.exportToPdf(
        this.exportFilename,
        this.title,
        this.defaultSubtitle
      )
    },
    async exportHtmlToPdf() {
      const pdf = new Pdf({
        defaultBodyMargin: { left: 40, top: 40 }
      })
      const element = this.exportableHtmlElement.$el

      await pdf.addPage()

      await pdf.addRow({}, async (row) => {
        await row.addCol({}, async (col) => {
          await col.addHtmlElement(element, { html2CanvasOptions: { windowWidth: 1000 } })
        })
      })

      pdf.save(`${this.exportFilename}.pdf`)
    }
  }
}