<template>
  <WStatsWidget
    class="feedback-treatment-ranking-widget"
    :title="title"
    :subtitle="defaultSubtitle"
    height="200px"
    :loading="isLoading"
    :cols="cols"
    ref="widget"
  >
    <template #content>
      <WPositionWidget
        :position="ranking"
        :evolution="networkEvolution"
        :displayEvolution="displayEvolution"
        :nrTooltipText="nrTooltipText"
      />
    </template>

    <template #footer-left>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import FeedbackTreatmentMixin from '@statistics/shared/feedback_treatment_mixin'

export default {
  name: 'FeedbackTreatmentRankingWidget',
  mixins: [
    WidgetMixin,
    FeedbackTreatmentMixin
  ],
  props: {
    campaign: { required: false },
    cols: { type: Number, default: 3 },
  },
  data() {
    return {
      ranking: undefined,
      decimals: 0,
      displayEvolution: true
    }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterReady',
      'isMonoPlace',
      'datesScope',
      'networkRankingPlaceIds',
    ]),
    isLoading() {
      return this.ranking === undefined
    },
    nrTooltipText() {
      return this.$t('feedbackTreatmentWidgetTootip')
    },
    title() {
      return `${this.$t('feedbackTreatmentRankingWidgetTitle')} - ${this.$t('feedback_treatment_widget_title')}`
    },
    networkEvolution() {
      const previousPeriodNetworkDatas = this.rankFor(this.previousPeriodNetworkDatas, this.indicatorProcessingTimeAlias)
      const actualPeriodNetworkDatas = this.rankFor(this.actualPeriodNetworkDatas, this.indicatorProcessingTimeAlias)

      if (actualPeriodNetworkDatas != null && previousPeriodNetworkDatas != null) {
        this.displayEvolution = true
        return previousPeriodNetworkDatas - actualPeriodNetworkDatas
      } else {
        this.displayEvolution = false
        return null
      }
    }
  },
  asyncComputed: {
    previousPeriodNetworkDatas: {
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace) {

          const previousPeriodNetworkRequest = this.avgProcessingTimeRequest().where({
            "place_campaigns_place_id": this.networkRankingPlaceIds
          }).group([
            "place_campaigns_place_id"
          ]).order([this.avgProcessingTimeColumns(), "asc"])

          const previousPeriodNetworkDatas = await this.$resolve(previousPeriodNetworkRequest)
          return previousPeriodNetworkDatas.data.insatisfactionProcessingTimes
        }
      },
      default: undefined
    },
    actualPeriodNetworkDatas: {
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace) {

          const actualPeriodNetworkRequest = this.avgProcessingTimeRequest().where({
              "place_campaigns_place_id": this.networkRankingPlaceIds
            }).group([
              "place_campaigns_place_id"
            ]).order([this.avgProcessingTimeColumns(), "asc"])

          const actualPeriodNetworkDatas = await this.$resolve(actualPeriodNetworkRequest)
          return actualPeriodNetworkDatas.data.insatisfactionProcessingTimes
        }
      },
      default: undefined
    }
  },
  watch: {
    actualPeriodNetworkDatas() {
      this.ranking = this.rankFor(this.actualPeriodNetworkDatas, this.indicatorProcessingTimeAlias)
    }
  }
}
</script>
