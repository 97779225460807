<template>
  <div class="trustvill-state-export">
    <v-card
      class="d-flex justify-space-between pr-4 pl-4 ml-4 mt-4 mr-4"
      outlined
    >
      <v-card-title>
        Export des status Trustville
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="exportTrustvilleState"
        >
          Exporter
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "EmailingsExport",
    data() {
      return {
        loading: false
      }
    },
    computed: {
      ...mapGetters([
        'mainCampaignId',
        'placeIds',
        'sqlDateBegin',
        'sqlDateEnd',
        'dashboardFilterProductIds'
      ])
    },
    methods: {
      async exportTrustvilleState() {
        this.loading = true

        try {
          const response = await this.$api.wizville.campaign.export_trustville_state(
            this.mainCampaignId,
            this.placeIds,
            this.dashboardFilterProductIds,
            this.sqlDateBegin,
            this.sqlDateEnd
          )

          this.$store.dispatch('notifySuccess', {
            message: `Retrouvez votre export sur ${response.email} lorsqu'il sera prêt`,
            timeout: 5000,
          })
        } catch (error) {
          console.log(error)
          this.$store.dispatch('notifyError', {
            timeout: 5000
          })
        }

        this.loading = false
      }
    }
  }
</script>
