<template>
  <div v-if="currentDashboard.preferences.isLrmEligible && campaignSmartBenchmarkPreferences !== undefined" class="competitions">
    <competitor-mode-type-nav
      :class="navigationClass"
      class="competitions-navigation"
      v-if="!fullScreenMode"
      :campaignSmartBenchmarkPreferences="enabledCampaignSmartBenchmarkPreferences"
    />
    <LmBrands
      v-if="competitorMode == 'country'"
      :countryCode="periodType"
      :activeLmBrandId="activeLmBrandId"
      :key="`lm-brands-${competitorMode}-${periodType}-${campaignSmartBenchmarkPreference?.id}`"
      :campaignSmartBenchmarkPreference="campaignSmartBenchmarkPreference"
    />
    <LmPlaces
      v-else
      :competitorMode="competitorMode"
      :competitorScope="competitorScope"
      :activeLmPlaceId="activeLmPlaceId"
      :periodType="periodType"
      :key="`lm-places-${competitorMode}-${periodType}-${campaignSmartBenchmarkPreference?.id}`"
      :campaignSmartBenchmarkPreference="campaignSmartBenchmarkPreference"
     />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import LmBrands from './LmBrands'
  import LmPlaces from './LmPlaces'
  import CompetitorModeTypeNav from './navigation/CompetitorModeTypeNav'

  export default {
    name: "CompetitionsPage",
    components: {
      LmPlaces,
      LmBrands,
      CompetitorModeTypeNav
    },
    props: {
      competitorMode: {
        required: true,
        type: String
      },
      competitorScope: {
        required: true
      },
      periodType: {
        required: true,
        type: String
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboard",
        "defaultCompetitorScope",
        "currentDashboardCampaignIds",
        "isMonoPlace",
        "lmGmbPlaceIdsScope",
        "fullScreenMode",
        "sidebarMinimized"
      ]),
      navigationClass() {
        return {
          'width-sidebar-mini': this.sidebarMinimized && this.$vuetify.breakpoint.smAndUp,
          'width-sidebar-maxi': !this.sidebarMinimized && this.$vuetify.breakpoint.smAndUp
        }
      },
      activeLmBrandId() {
        return this.competitorScope === 'default' ? null : this.competitorScope
      },
      activeLmPlaceId() {
        const activeLmPlaceId = isNaN(parseInt(this.competitorScope)) ?
                                Number(this.lmGmbPlaceIdsScope[0]) :
                                Number(this.competitorScope)

        if (this.competitorMode === 'local' &&
            this.isMonoPlace &&
            activeLmPlaceId
        ) {
          return activeLmPlaceId
        }
      },
      enabledCampaignSmartBenchmarkPreferences() {
        return this.campaignSmartBenchmarkPreferences?.filter(
          (campaignSmartBenchmarkPreference) => campaignSmartBenchmarkPreference.enabled
        )
      },
      defaultCampaignSmartBenchmarkPreference() {
        return this.enabledCampaignSmartBenchmarkPreferences?.find(
          (campaignSmartBenchmarkPreference) => campaignSmartBenchmarkPreference.isDefault
        )
      },
      campaignSmartBenchmarkPreference() {
        return this.enabledCampaignSmartBenchmarkPreferences?.find(
          (campaignSmartBenchmarkPreference) => campaignSmartBenchmarkPreference.id === Number(this.campaignSmartBenchmarkPreferenceId)
        ) || this.defaultCampaignSmartBenchmarkPreference
      }
    },
    asyncComputed: {
      campaignSmartBenchmarkPreferences: {
        default: undefined,
        async get() {
          const request = this.$basedRequest().select({
            campaign_smart_benchmark_preferences: [
              { 'id': { as: 'id' } },
              { 'name': { as: 'name' } },
              { 'enabled': { as: 'enabled' } },
              { 'is_default': { as: 'isDefault' } },
              { 'eligible_lm_brand_ids': { as: 'lmBrandIds', params: { country_code: this.periodType } } },
              { 'min_topic_review_for_ranking': { as: 'minTopicReviewForRanking' } },
              { 'nb_month_for_topic_weight': { as: 'nbMonthForTopicWeight' } },
              { 'by_default_display_themes': { as: 'byDefaultDisplayThemes' } }
            ]
          }).where({
            campaign_id: this.currentDashboardCampaignIds,
          })

          const campaignSmartBenchmarkPreferences = (await this.$resolve(request))?.data?.campaignSmartBenchmarkPreferences || []

          campaignSmartBenchmarkPreferences.forEach((campaignSmartBenchmarkPreference) => {
            campaignSmartBenchmarkPreference.lmBrandIds = [
              ...this.currentDashboard.lmBrandIds,
              ...campaignSmartBenchmarkPreference.lmBrandIds
            ]
          })

          return campaignSmartBenchmarkPreferences
        }
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/constants.styl'

  .competitions
    padding-top: "calc(%s + %s)" % ($header-menu-height $filters-height)

    .competitions-navigation
      position: fixed
      transition: width 250ms
      top: "calc(%s + %s + 1px)" % ($header-menu-height $filters-height)
      z-index: 4
      &.width-sidebar-mini
        width: "calc(100% - %s)" % $sidebar-mini-width
      &.width-sidebar-maxi
        width: "calc(100% - %s)" % $sidebar-width
</style>