import ChartsDefaultOptionsMixin from './charts_default_options_mixin'
import deepmerge from '@shared/helpers/deepmerge';

export default {
  mixins: [
    ChartsDefaultOptionsMixin
  ],
  methods: {
    defaultTooltipContent() {
      return null
    },
    tooltipContent() {
      return null
    }
  },
  computed: {
    chartDefaultOptions() {
      const localOptions = {
        chart: {
          type: 'column',
          height: "400px",
        },
        legend: {
          enabled: true
        },
        xAxis: {
          gridLineWidth: 0
        },
        tooltip: {
          enabled: false
        }
      }

      return deepmerge(this.sharedDefaultOptions, localOptions)
    }
  }
}