<template>
  <div class="w-drop-down-menu">
    <v-menu
      left
      transition="slide-y-transition"
      offset-y
      min-width="100px"
      :disabled="disabled"
      @update:return-value="open = false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="w-drop-down-button d-flex mx-1 py-2 px-3 align-center rounded menu"
          :class="{ 'bg-mercury': open }"
          @click="open = !open"
          v-bind="attrs"
          v-on="on"
        >
        <slot name="activator-content" :open="open">
            <div class="d-flex align-center">
              <v-icon v-if="icon" small>
                {{ icon }}
              </v-icon>
              <span v-else-if="emoji" class="mr-1">
                {{ emoji }}
              </span>
              <span v-if="title" class="mr-1">
                {{ title }}
              </span>
              <div v-if="showArrow">
                <v-icon v-if="open" small>
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else small>
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>
          </slot>
        </div>
      </template>

      <v-list class="bg-palegrey py-0 w-drop-down-menu-content">
        <v-list-item-group v-model="computedValue">
          <template v-for="(item, index) in items">
            <v-list-item
              v-if="item.children"
              class="py-0 px-0"
              :key="index"
            >
              <v-list-item-content class="py-0">
                <v-list-item-group
                  class="flex-grow-1"
                  :class="{ 'mt-1': index !== 0 }"
                  v-model="computedValue[index]"
                >
                  <v-list-item-title
                    v-if="showGroupTitle"
                    class="py-2 px-3"
                  >
                    <a class="fs-13">{{ item.title }}</a>
                    <v-tooltip v-if="item.help" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="f-12 mr-2 c-primary">
                          mdi-help-circle
                        </v-icon>
                      </template>
                      <span>{{ item.help }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item
                    v-for="(child, childIndex) in item.children"
                    class="item py-1 px-3"
                    :key="childIndex"
                    :value="child.value"
                    active-class="bg-catskill-white"
                    :class="{ 'bg-catskill-white': child.value && child.value === computedValue[index] }"
                    :style="listItemHeightStyle"
                    @click="handleClick(child, index)"
                  >
                    <v-list-item-title>
                      <div>
                        <v-icon small class="mr-1" v-if="child.icon">
                          {{ child.icon }}
                        </v-icon>
                        <span v-else-if="child.emoji" class="mr-1">
                          {{ child.emoji }}
                        </span>
                        <span class="f-13">
                          {{ child.title }}
                        </span>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else
              class="item py-1 px-3"
              :key="index"
              :value="item.value"
              active-class="item.value ? bg-catskill-white : f-13"
              :class="{ 'bg-catskill-white': item.value && item.value === computedValue }"
              :style="listItemHeightStyle"
            >
              <v-list-item-title
                class="d-flex"
                @click="handleClick(item)"
              >
                <div :class="item.class">
                  <v-icon v-if="item.icon" small>
                    {{ item.icon }}
                  </v-icon>
                  <span v-else-if="item.emoji" class="mr-1">
                    {{ item.emoji }}
                  </span>
                  <span v-if="item.title" class="f-13">
                    {{ item.title }}
                  </span>
                </div>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'WDropDownMenu',
    props: {
      items: { required: true, type: Array },
      title: { required: false, type: String },
      emoji: { required: false, type: String },
      icon: { required: false, type: String },
      showArrow: { required: false, type: Boolean, default: true },
      showGroupTitle: { required: false, type: Boolean, default: true },
      disabled: { required: false, type: Boolean, default: false },
      value: { required: false },
      listItemHeightStyle: {
        required: false,
        type: Object,
        default: () => ({ minHeight: '28px' })}
    },
    data() {
      return {
        open: false
      }
    },
    computed: {
      // To avoid firing error in console about changing props
      computedValue: {
        get() {
          return this.value
        },
        set() {
        }
      }
    },
    methods: {
      handleClick(item, parentIndex) {
        if (item.onClick) {
          item.params ? item.onClick(item.params) : item.onClick()
        } else {
          let inputValue = { ...this.value }

          if (parentIndex !== undefined) {
            inputValue[parentIndex] = item.value
          } else {
            inputValue = item.value
          }

          this.$emit("input", inputValue)
        }
      }
    },
    watch: {
      open() {
        this.$emit(this.open ? "open" : "close")
      },
    },
  }
</script>

<style lang="stylus">
  @import "~@theme/colors.styl"

  .w-drop-down-menu
    &:hover
      .w-drop-down-button
        background-color: rgb(233, 233, 233) !important

  .w-drop-down-menu
    .menu
      &:hover
        background-color: rgb(233, 233, 233) !important

  .w-drop-down-menu-content
    .v-list-item
      cursor: pointer

    .v-list-item--link:before
      background-color: unset

    .item.v-list-item--active
      span
        font-family: SourceSansProSemiBold !important

    .item
      transition: background-color 350ms

      &:hover,
      &:focus
        background-color: rgb(233, 233, 233)
</style>
