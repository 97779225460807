<template>
  <WStatsWidget
    ref="widget"
    :cols="cols"
    :footerSpacer="footerSpacer"
    :subtitle="defaultSubtitle"
    :title="title"
    :linkTo="linkTo"
    :loading="isLoading"
    :empty="!displayAvgProcessingTime"
    :emptyLabel="$t('feedback_treatment_widget_no_data')"
    class="feedback-treatment-widget"
    height="200px"
  >
    <template #help-tooltip>
      <v-tooltip top transition="none">
        <template #activator="{ on }">
          <v-icon v-on="on" class="f-16 c-oslo-gray">
            mdi-information
          </v-icon>
        </template>
        <div class="d-flex flex-column" :style="{ width: '400px' }">
          <div class="fs-13">{{ $t('feedback_treatment_widget_help_tooltip_main') }}</div>
          <div class="f-13">{{ $t('feedback_treatment_widget_help_tooltip_secondary') }}</div>
        </div>
      </v-tooltip>
    </template>
    <template #content>
      <span class="d-flex flex-column align-center ga-1">
        <span><HumanizeDuration :duration="humanizeAvgProcessingTime" /></span>
        <span v-if="nbProcessedInsatisfaction" class="sub-text f-12 c-lightgrey">{{ translateNbReview(nbProcessedInsatisfaction) }}</span>
      </span>
    </template>

    <template #footer-right v-if="networkRanking && isMonoPlace">
      <span class="fb-12">
        {{ networkRankingName }} :
      </span>
      <span>{{ networkRanking }}{{ $options.filters.ordinal(networkRanking) }}</span>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import FeedbackTreatmentMixin from '@statistics/shared/feedback_treatment_mixin'
import HumanizeDuration from './HumanizeDuration'

export default {
  name: 'FeedbackProcessingTimeWidget',
  mixins: [
    WidgetMixin,
    FeedbackTreatmentMixin,
  ],
  components: {
    HumanizeDuration
  },
  props: {
    campaign: { type: Object },
    cols: { type: Number, default: 3 },
    footerSpacer: { type: Boolean, default: false },
  },
  data() {
    return {
      decimals: 0
    }
  },
  methods: {
    translateNbReview(nbReviews) {
      return this.$t('feedback_treatment_widget_nb_review_treated', {
        nbReviews: nbReviews
      })
    }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterReady',
      'isMonoPlace',
      'networkRankingName',
      'networkRankingPlaceIds',
      'placeIds',
    ]),
    isLoading() {
      return this.avgProcessingTime === undefined
    },
    title() {
      return this.$t('feedback_treatment_widget_title')
    },
    networkRanking() {
      return this.rankFor(this.networkDatas, this.indicatorProcessingTimeAlias)
    },
    displayAvgProcessingTime() {
      if (this.dashboardFilterReady && this.avgProcessingTime)
        return this.avgProcessingTime[this.indicatorProcessingTimeAlias] != null
    },
    humanizeAvgProcessingTime() {
      return this.avgProcessingTime[this.indicatorProcessingTimeAlias]
    },
    linkTo() {
      if (this.$route.params.sectionType === "summary") {
        return {
          name: 'Statistics',
          params: { sectionType: 'feedbackTreatment', sectionId: "default", subSectionId: this.campaign?.id }
        }
      }
    }
  },
  asyncComputed: {
    nbProcessedInsatisfaction: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.dashboardFilterReady) {
          const request = this.nbInsatisfactionsProcessedRequest()
          return (await this.$resolve(request)).first()?.nbProcessedInsatisfactions || 0
        }
      },
    },
    avgProcessingTime: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.dashboardFilterReady) {
          const request = this.avgProcessingTimeRequest()

          return (await this.$resolve(request)).first()
        }
      },
    },
    networkDatas: {
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace && this.networkRankingPlaceIds) {

          const request = this.avgProcessingTimeRequest().where({
              "place_campaigns_place_id": this.networkRankingPlaceIds
            }).group([
              "place_campaigns_place_id"
            ]).order([this.avgProcessingTimeColumns(), "asc"])

          const networkRankingDatas = await this.$resolve(request)

          return networkRankingDatas.data.insatisfactionProcessingTimes
        }
      },
      default: null,
    },
  }
}
</script>
