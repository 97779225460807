<template>
  <span class="lh-16" v-if="voterSource" :key="reloadKey">
    <span v-if="isPublishableAndEligible">
      <v-tooltip top :disabled="isOnline">
        <template #activator="{ on }">
          <v-hover v-slot="{ hover: hoverOnPublishable }">
            <span v-on="on">
              <span>
                <WIcon
                  icon="trustville_gradient"
                />
              </span>
              <span>
                <span class="fb-13 c-primary" v-if="(hoverOnPublishable && displayStateOnHover) && $vuetify.breakpoint.smAndUp">Trustville</span>
                <span class="f-11 c-purple" v-if="displaySubState || hoverOnPublishable || hover">{{ $t(`trustvilleBadgeMainStatus${displayedStatus}`) }}</span>
              </span>
            </span>
          </v-hover>
        </template>

        {{ trustvillePublicLabel }}
      </v-tooltip>
    </span>
    <span v-else>
      <v-tooltip top :disabled="!trustvillePublicState">
        <template #activator="{ on }">
          <span v-on="on">
            <span class="mr-1">
              <WSourceIcon :source="voterSource" class="pl-1" />
            </span>
            <span>
              <span class="f-11 c-lightgrey" v-if="displaySubState && trustvillePublicState">{{ $t(`trustvilleBadgeMainStatus${displayedStatus}`) }}</span>
            </span>
        </span>
        </template>
        {{ trustvillePublicLabel }}
      </v-tooltip>
    </span>
  </span>
</template>

<script>

const PUBLIC_STATE = {
  soon_online: "soon_online",
  online: "online",
  unpublished: "unpublished",
  under_moderation: "under_moderation"
}

export default {
  name: 'WTrustvilleBadge',
  props: ['voterSource', 'trustvillePublicState', 'trustvillePublicLabel', 'displayStateOnHover', 'displaySubState', 'hover'],
  computed: {
    isPublishableAndEligible() {
      return (
        [PUBLIC_STATE['soon_online'], PUBLIC_STATE['online'], PUBLIC_STATE['under_moderation']].includes(this.trustvillePublicState)
      )
    },
    isSoonOnline() {
      return this.trustvillePublicState == PUBLIC_STATE['soon_online']
    },
    isUnpublished() {
      return this.trustvillePublicState == PUBLIC_STATE['unpublished']
    },
    isUnderModeration() {
      return this.trustvillePublicState == PUBLIC_STATE['under_moderation']
    },
    isOnline() {
      return this.trustvillePublicState == PUBLIC_STATE['online']
    },
    displayedStatus() {
      if (this.isSoonOnline) {
        return "soonOnline"
      } else if (this.isUnpublished) {
        return "unpublished"
      } else if (this.isUnderModeration) {
        return "underModeration"
      } else if (this.isOnline) {
        return "online"
      } else {
        return "Unknown status"
      }
    },
    reloadKey() {
      return `${this.trustvillePublicState}-${this.trustvillePublicLabel}-${this.voterSource}`
    }
  }
}
  
</script>
