<template>
  <div>
    <v-menu
      right
      :bottom="true"
      :dense="dense"
      :disabled="disabled"
      v-model="open"
      :content-class="`${fixTopOffset ? 'w-autocomplete-menu-offset' : ''}`"
      :close-on-content-click="false"
      :attach="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="w-autocomplete d-flex px-3 align-center text-truncate"
          ref='wAutocomplete'
          :class="{
            'elevation-1': !flat,
            'w-autocomplete-rounded': rounded,
            'w-autocomplete-transparent': transparent,
            'w-autocomplete-outlined-light': !isMenuExpanded(attrs) && outlined && !isDefaultTheme,
            'w-autocomplete-outlined-default': !isMenuExpanded(attrs) && outlined && isDefaultTheme,
            'w-autocomplete-background-default': isDefaultTheme
          }"
          :style="{ height: height, minWidth: minWidth }"
          v-bind="attrs"
          v-on="on"
        >
          <div class="dropdown-title d-flex align-center mr-auto">
            <w-icon v-if="titleIcon" :icon="titleIcon" class="c-accent filter-icon mr-1" />
            <span
              class="mr-auto"
              :class="
              `selected-text f-14 text-truncate
              ${isDefaultTheme ? 'c-dark' : 'c-white'}`"
            >
              {{ dropdownTitle }}
            </span>
          </div>
          <span
            v-if="multiple && value.length"
            class="d-flex flex-shrink-0 align-center"
            :class="
            `selected-text f-14 text-truncate
            ${isDefaultTheme ? 'c-dark' : 'c-white'}`"
          >
            <v-badge
              :class="`${textColor}`"
              inline
              :content="valueLengthToString"
            />
          </span>
          <v-icon size="medium" :color="`${isDefaultTheme ? 'dark' : 'white' }`">
            {{ open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </div>
      </template>

      <v-slide-x-reverse-transition leave-absolute>
        <v-list class="py-0" dense :style="{ maxWidth: menuWidth }">
          <v-col :class="`${border ? 'px-1 py-1' : 'px-0 py-0'}`" :style="{ borderRadius: '4px'}">
            <v-text-field
              class="w-autocomplete-text-field "
              v-model="debouncedSearch"
              :label="capitalize($t('search'))"
              :color="color"
              :solo="solo"
              :dark="isBackgroundDark"
              :background-color="backgroundColor"
              @keydown.enter.prevent
              autofocus
              outlined
              dense
              :rules="rules"
              hide-details
            >
              <template slot="append">
                <v-icon v-if="!multiple" :class="`${textColor}`">{{ this.icon }}</v-icon>
                <span v-else>
                  <v-badge
                    inline
                    :class="`${textColor}`"
                    :content="valueLengthToString"
                  />
                </span>
              </template>
            </v-text-field>

            <template v-if="$slots.more">
              <div class="d-flex px-3">
                <slot name="more" />
              </div>
              <v-divider></v-divider>
            </template>

            <v-progress-linear
              v-if="loading"
              class="mt-1"
              indeterminate
              color="primary"
            />
            <v-chip-group
              column
              v-if="multiple"
            >
            <v-chip
              v-for="(itemValue) in value"
              :key="itemValue"
              :ripple="false"
              small
            >
              {{ valuesTextsHash[itemValue] }}
            </v-chip>
           </v-chip-group>
          </v-col>

          <v-list-item-group>
            <v-virtual-scroll
              v-if="!loading && filteredItems && filteredItems.length > 0"
              :items="filteredItems"
              max-height="250"
              item-height="30"
              bench="10"
            >
              <template v-slot:default="{ item }">
                <v-list-item
                  class="f-14"
                  :class="listItemClass(item)"
                  :key="`item-${item[keyValue]}`"
                  dense
                  :inactive="!!(item.header || item.divider)"
                  :disabled="!!(item.header || item.divider)"
                  @click="item[keyValue] ? updateItems(item[keyValue]) : null"
                >
                  <v-subheader v-if="item.header">
                    <span class="mt-2 fb-14 text-truncate">
                      {{ item.header }}
                    </span>
                  </v-subheader>
                  <v-list-item-content class="py-0" v-else-if="item[keyText]">
                    <v-list-item-title class="f-14">
                      <v-checkbox
                        v-if="multiple"
                        hide-details
                        color=""
                        v-model="value.includes(item[keyValue])"
                        class="mt-O pt-0"
                        dense
                      >
                        <template v-slot:label>
                          <span v-html="highlightText(item[keyText])" style="position: relative; bottom: 2px; left: 5px;" class="f-14">
                          </span>
                        </template>
                      </v-checkbox>
                      <span v-else v-html="highlightText(item[keyText])" class="text-truncate">
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-divider class="divider" v-else-if="item.divider"></v-divider>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list-item-group>
        </v-list>
      </v-slide-x-reverse-transition>
    </v-menu>
  </div>
</template>

<script>
import { capitalize } from 'inflection'
import _debounce from 'lodash/debounce'
import _isEqual from 'lodash/isEqual'

export default {
  name: "WAutocomplete",
  props: {
    value: { required: false },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    // for api call
    searchInput: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    titleIcon: {
      type: String,
      required: false
    },
    itemText: {
      type: String,
      required: false,
      default: "text"
    },
    itemValue: {
      type: String,
      required: false,
      default: "value"
    },
    // reset value when menu Open
    autoResetOnClose: {
      type: Boolean,
      required: false,
      default: false
    },
    // multi select
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean, 
      required: false,
      default: false
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    highlight: {
      type: Boolean,
      required: false,
      default: true
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: "primary"
    },
    minWidth: {
      type: String,
      required: false,
      default: "0px"
    },
    // theme light and default
    theme: {
      type: String,
      required: false,
      default: "default"
    },
    border: {
      type: Boolean,
      required: false,
      default: true
    },
    solo: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "white"
    },
    // fix top shift when on top of page
    fixTopOffset: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-magnify"
    },
    strict: {
      type: Boolean,
      required: false,
      default: true
    },
    attachRef: {
      required: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      open: false,
      search: null,
      keyText: this.itemText,
      keyValue: this.itemValue,
      menuWidth: null
    }
  },
  computed: {
    textColor() {
      return this.isBackgroundDark ? 'c-white' : 'c-primary'
    },
    valueLengthToString() {
      return this.value.length.toString()
    },
    isDefaultTheme() {
      return this.theme == "default"
    },
    isBackgroundDark() {
      return ["primary"].includes(this.backgroundColor)
    },
    filteredItems() {
      if (this.search) {
        const filteredItems = this.items.filter((item, index) => {
          let regex = new RegExp(
            this.search?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
            'i'
          )

          if (this.strict && !item.header && !item.divider) {
            return item[this.keyText]?.match(regex)
          }
          const nextItem = this.items[index + 1]
          if (this.strict && item.header || item.divider) {
            return item
          }

          return !this.strict
        })
        const filterHeaders = this.filterHeaders(filteredItems)
        const filterDividers = this.filterDividers(filterHeaders)
        return filterDividers
      } else {
        return this.items
      }
    },
    valuesTextsHash() {
      let valuesTextsHash = {}
      this.items.forEach(item => {
        if (!item.header)
          valuesTextsHash = {...valuesTextsHash, ...{ [item[this.keyValue]]: item[this.keyText] }}
      })
      return valuesTextsHash
    },
    selectedItems() {
      let currentItem = this.items.find(item => _isEqual(item[this.keyValue], this.value))
      if (currentItem) {
        return currentItem[this.keyText]
      } else {
        return null
      }
    },
    dropdownTitle() {
      if (this.multiple) {
        if (!this.value.length) {
          return this.label
        } else {
          return this.value.map(value => {
            return this.valuesTextsHash[value]
          }).join(', ')
        }
      } else {
        if (!this.value) {
          return this.label
        } else {
          return this.selectedItems
        }
      }
    },
    debouncedSearch: {
      get() {
        return this.search
      },
      set: _debounce(function (val) {
        this.search = val
      }, 100),
    },
    height() {
      if (this.dense == true) {
        return "30px"
      } else {
        return "40px"
      }
    }
  },
  methods: {
    // we use this method to force the maxWidth of the menu
    // usefull when we use the multiselect and we add chips
    setWidthMenu() {
      if (this.multiple && this.$refs.wAutocomplete) {
        this.menuWidth = `${this.$refs.wAutocomplete.clientWidth}px`
      }
    },
    highlightText(text) {
      if (this.highlight) {
        const originalRegex = new RegExp(this.search, "i")
        const originalText = text.match(originalRegex) && text.match(originalRegex)[0]

        const highlightText = `<span class="c-info font-weight-bold">${originalText}</span>`
        const searchRegex = new RegExp(this.search, "i")
        return text.replace(searchRegex, highlightText)
      } else {
        return text
      }
    },
    isMenuExpanded(attrs) {
      return attrs['aria-expanded'] == "true"
    },
    filterHeaders(items) {
      return items.filter((item, index) => {
        if (item[this.keyText] || item.divider) {
          return item
        }
        const nextItem = items[index + 1]

        if (item.header && nextItem && !nextItem.header && !nextItem.divider) {
          return item
        }
      })
    },
    filterDividers(items) {
      return items.filter((item, index) => {
        if (item[this.keyText] || item.header) {
          return item
        }
        const nextItem = items[index + 1]
        const previousItem = items[index - 1]

        if (item.divider && previousItem && nextItem && previousItem[this.keyText] && nextItem.header) {
          return item
        }
      })
    },
    listItemClass(item) {
      if(item.header) {
        return 'header'
      } else if (item.divider) {
        return 'divider'
      } else {
        return 'value'
      }
    },
    resetValues() {
      if (this.autoResetOnClose) {
        if (this.multiple) {
        this.$emit('input', []);
        this.$emit('change', []);
        } else {
          this.$emit('input', null);
          this.$emit('change', null);
        }
        this.search = null
      }
    },
    capitalize,
    close() {
      this.open = false
    },
    multipleValues(value) {
      let checkboxItems = this.value
      const filteredCheckboxArray = checkboxItems.filter(item => item != value)
      if (filteredCheckboxArray.length == checkboxItems.length) {
        checkboxItems.push(value)
      } else {
        checkboxItems = filteredCheckboxArray
      }

      return checkboxItems
    },
    updateItems(value) {
      if (this.multiple) {
        this.$emit('input', this.multipleValues(value));
        this.$emit('change', this.multipleValues(value));
      } else {
        this.$emit('input', value);
        this.$emit('change', value);
        this.open = false
      }
    }
  },
  watch: {
    search: function() {
      this.$emit('update:search-input', this.search);
    },
    open() {
      if (this.open == true) {
        this.resetValues()
        this.setWidthMenu()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@theme/colors.styl'

.v-menu__content
  max-width 300px !important
  .v-list-item__content
    padding 0 !important
  .v-subheader
    padding-left: 0px
  .v-list-item
    min-height: 30px
    max-height: 30px
  .v-list-item.divider
    min-height: 10px
    max-height: 10px

.w-autocomplete-menu-
  &offset
    margin: -8px 0 0 0

.w-autocomplete
  flex-basis: 275px
  min-width: 100px
  position: relative

  .w-autocomplete-fieldset
    position: absolute
    right: 0
    top: -5px
    left: 0
    bottom: 0

  &.w-autocomplete-background-default
    background-color white

  &.w-autocomplete-rounded
    border-radius: 3px

  &.w-autocomplete-outlined-default
    border: solid 1px rgba(30, 84, 123, 0.3)

    &:hover
      border: solid 1px rgba(30, 84, 123, 1)  

  &.w-autocomplete-outlined-light
    border: solid 1px rgba(255, 255, 255, 0.3)

    &:hover
      border: solid 1px rgba(255, 255, 255, 1)

  &.w-autocomplete-transparent
    opacity: 0.2

  .dropdown-title
    min-width: 0px

.v-list-item__title
  white-space normal !important

</style>
